import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-not-authorised-modal',
  standalone: true,
  templateUrl: './not-authorised-modal.component.html',
  styleUrls: ['./not-authorised-modal.component.scss'],
  imports: [CommonModule],
})
export class NotAuthorisedModalComponent {
  title?: string;
  protected isDemo = false;

  constructor(
    public authService: AuthService,
    public bsModalRef: BsModalRef,
  ) {
    this.isDemo = location.hostname.includes('demo.fairsupply.io');
  }

  signIn() {
    this.authService.logout$().subscribe();
  }
}
